/*
.subtitle__text-bottom {
    margin-top: 1em;
}
 
.search__input {
    display: flex;
    margin-top: 3em;
}

.grid {
    margin-top: 2em;
    
}
*/

.title_wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
    @media (max-width: 1100px) {
        width: 100%;
    }
}

.line {
    word-wrap: normal;
    display: grid;
    grid-template-columns: 20% 30% 15% 17.5% 17.5%;
    @media (max-width: 1100px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0.5em;
    }
  }

.wrap__items {
    font-size: 0.9rem;
    color: black;
    padding: 0.75rem 0;
    vertical-align: top;
    text-align: left;
    padding-left: 1em;
}

.item {
    font-size: 0.9rem;
    @media (max-width: 1100px) {
        font-size: 1rem;
    }
}

.item-name {
    font-size: 0.9rem;
    @media (max-width: 1100px) {
        font-weight: 700;
    }
}

.line > a  {
    color: #4B7196 !important;
    font-weight: 500;
}

.line >  a:hover{
    // font-weight: 500;
    color: #2B3956 !important;
}

.wrap__items:not(:first-child) {
    border-top: 1px solid white;
    
}

.lei {
    margin-left: 0;
}

.inner__items {
    margin-top: 2em;
}

.wrap__items:nth-child(2n) {
    border-radius: 5px;
    background-color: #CFE2F3;
}

.status {
    color: #4B7196;
    text-transform: uppercase;
    font-weight: 500;
}
    
.status-rejected {
    color: red;
}

// PAGINATE START

.container__paginate {
    display: flex;
    list-style-type: none;
    margin-top: 10px;
    justify-content: center;
    cursor: pointer;
    border-radius: 0.15rem;
    color: #4B7196;
    background-color: #fff;
}

.page__class-name {
    padding: 4px;
    margin-right: 4px;
    border: 1px solid #dee2e6;
}

.previous__class-name {
    padding: 3px;
    margin-right: 4px;
    border: 1px solid #dee2e6;
}

.next__class-name {
    padding: 3px;
    border: 1px solid #dee2e6;
}

.active__class-name {
    background-color: #4B7196;
    border-color: #4B7196;
    color: #fff;
}
    

// PAGINATE STOP

// Banner

.mt-8 {
    margin-top: 2rem;
}