.table
    padding: 20px
    border: 1px solid lightgray
    @media (max-width: 1100px)
        border: 1px solid white
        border-radius: 5px
        margin: 2em 2em 0 2em
        padding: 0

.row-head
    display: flex
    @media (max-width: 1100px)
        display: flex 
        flex: 100%
        flex-wrap: wrap
        text-align: center
        border: 1px solid lightgrey
        border-radius: 0.5em
        margin-bottom: 1em
        padding-bottom: 1em

.row-head-company-name
    display: flex
    @media (max-width: 1100px)
        display: flex 
        flex: 100%
        flex-wrap: wrap
        justify-content: center
        text-align: center
        border: 1px solid lightgrey
        border-radius: 0.5em
        padding-bottom: 1em
        margin-bottom: 2em

.row
    display: flex
    @media (max-width: 1100px)
        display: grid 
        gap: 0.5rem
        grid-template-columns: 1fr 1fr
        word-wrap: break-word

.item-head-title
    padding: 10px 0px
    width: 20%
    font-weight: 600
    @media (max-width: 1100px)
        width: 100%
        padding: 0
        margin-top: 1em

.item-head
    padding: 10px 0px
    width: 30%
    padding-right: 1em
    @media (max-width: 1100px)
        width: 100%
        padding: 0
        margin-top: 1em

.item-title
    padding: 10px 0px
    width: 20%
    font-weight: 600
    @media (max-width: 1100px)
        width: 100%
        font-size: 0.9em
        padding: 0.5em 0

.item
    padding: 10px 0px
    width: 30%
    padding-right: 1em
    @media (max-width: 1100px)
        width: 100%
        font-size: 0.9em
        padding: 0.5em 0

.title
    font-weight: 600

.status-ISSUED
    border: 2px solid green
    border-radius: 5px
    color: green
    text-align: center
    font-weight: 600
    justify-content: center
    padding: auto 0
    @media (max-width: 1100px)
        padding: 1em 0
        border: 0
    

.status-LAPSED
    border: 2px solid #CE0000
    border-radius: 5px
    color: #CE0000
    text-align: center
    font-weight: 600
    @media (max-width: 1100px)
        padding: 1em 0
        border: 0

.status-SUBMITTED
    border: 2px solid #2B3956
    border-radius: 5px
    color: #2B3956
    text-align: center
    font-weight: 600
    @media (max-width: 1100px)
        padding: 1em 0
        border: 0

.subvalues
    margin-top: 15px
    border-top: 1px solid lightgray
    padding-top: 15px
    @media (max-width: 1100px)
        border-top: 0
        margin: 0
        padding: 0

.hr
    margin-top: 30px
    opacity: .4
    @media (max-width: 1100px)
        display: none
