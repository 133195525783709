

.container {
    max-width: 1000px;
    margin: 2rem auto;
}

.lei-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1px solid lightgray;
    padding-bottom: 20px; */
}

h1{
    color: #2B3956;
    font-size: 2rem;
    font-weight: bold;
}

.tabs{
    display: flex;
    margin-top: 30px;
    align-items: center;
}

.tabs > a {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    box-sizing: border-box;
    color: black;
    border-left: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    
}

.tabs > a:hover{
    color: #4B7196;
}

.back{
    width: 150px;
    background-color: #fff;
    padding: 10px;
    text-align: center;
    color: #2B3956;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #2B3956;
}
.back:hover{
    background-color: #4B7196;
    color: white;
    border: 1px solid #4B7196;
}

.active-tab{
    border-left: 1px solid lightgray !important;
    border-top: 1px solid lightgray !important;
    border-right: 1px solid lightgray !important;
    background-color: #CFE2F3;
}

@media (max-width: 1100px) {

    .lei-title{ 
        flex: 100%;
        flex-wrap: wrap;
        justify-content: center;
        /* padding-bottom: 2em; */
    }

    h1 {
        margin: 0 40px 0 40px;
    }

    .back{ 
        margin: 1em 40px 0 40px;
        justify-content: center;
        display: flex;
        flex: 100%;
        flex-wrap: wrap;
        max-width: 150px
    }

    .tabs{
        justify-content: center;
    }

    .tabs > a {
        border-left: 1px solid white;
        border-top: 1px solid white;
        border-right: 1px solid white;
        
    }

    .active-tab {
        border-bottom: 1px solid lightgray !important;
        border-radius: 5px;
    }
}