.subtitle__text-bottom {
    font-weight: bold;
    line-height: 1.5;
}

.grid {
    margin-top: 20px;
}


.line {
    word-wrap: normal;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
  }

.wrap__items {
    font-size: 14px;
    color: #212529;
    padding: 0.75rem 0;
    vertical-align: top;
    text-align: left;
     
}
.line > a  {
    color: #4B7196 !important;
}

.line >  a:hover{
    text-decoration: underline;
    color: #212529 !important;
}

.wrap__items:not(:first-child) {
    border-top: 1px solid #dee2e6;
}

.lei {
    margin-left: 10px;
}

.inner__items {
    margin-top: 20px;
}

.wrap__items:nth-child(2n) {
    background-color: rgba(0,0,0,.05);
}

// PAGINATE START

.container__paginate {
    display: flex;
    list-style-type: none;
    margin-top: 10px;
    justify-content: center;
    cursor: pointer;
    border-radius: 0.15rem;
    color: #4B7196;
    background-color: #fff;
}

.page__class-name {
    padding: 4px;
    margin-right: 4px;
    border: 1px solid #dee2e6;
}

.previous__class-name {
    padding: 3px;
    margin-right: 4px;
    border: 1px solid #dee2e6;
}

.next__class-name {
    padding: 3px;
    border: 1px solid #dee2e6;
}

.active__class-name {
    background-color: #4B7196;
    border-color: #4B7196;
    color: #fff
}



// PAGINATE STOP
