// Wrap Create LEI application
.title_wrap
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin-top: 2rem
    @media(max-width: 768px)
        width: 100%

.create_lei-wrap
    border-radius: 0.25rem
    // margin-top: 30px
    align-items: center

// Button Create LEI application
.button
    line-height: 1.2
    margin-left: 25px
    font-size: 1.5rem
    // font-weight: bold
    border: none
    cursor: pointer
    color: #4B7196
    background: none
    transition: all 0.3s
    height: 80px
    &:hover
        color: #2B3956
        text-decoration-line: underline
    @media (max-width: 1100px)
        font-size: 1.5rem
        margin-left: 0

// Inner Top for Create LEI application button
.inner_button-lei
    border: 1px solid rgba(0,0,0,.125)
    background-color: rgba(0, 0, 0, 0.03)
    border-top-left-radius: 5px
    border-top-right-radius: 5px
    @media (max-width: 1100px)
        text-align: center

// Style of Item in the Table
.item
    font-size: 1em

//Style of Company Name in the Table
.item-name
    font-size: 1em
    font-weight: 500

// Inner Bottom for Create LEI application button
.create_leicode
    border-left: 1px solid rgba(0,0,0,.125)
    border-right: 1px solid rgba(0,0,0,.125)
    border-bottom: 1px solid rgba(0,0,0,.125)
    background-color: #fff
    border-bottom-left-radius: 5px
    border-bottom-right-radius: 5px
    align-items: center
    padding: 2rem 1.25rem

// Description top text area right from the buttons
.hidden_top
    display: flex
    justify-content: center
    @media (max-width: 1100px) 
        display: grid
        grid-template-columns: 1fr
        grid-gap: 0.5em
    
// Buttons style
.button_top
    color: #fff
    background-color: #4B7196
    align-items: center
    cursor: pointer
    padding: 0.375rem 0.75rem
    font-size: 1rem
    min-width: 150px
    line-height: 1.5
    border-radius: 5px
    border: none
    transition: all .3s
    &:hover
        background-color: #2B3956

// Buttons Text separate syle
.button_text
    margin-left: 5px

// Top description text style
.top_text
    // margin: 0 2em
    width: 75%
    word-wrap: break-word
    text-align: justify
    @media (max-width: 1100px)
        margin: 1em 0 0 0
        width: 100%
        text-align: center

// Description bottom text areas right from the buttons
.hidden_bottom
    margin-top: 2rem
    display: flex
    justify-content: center
    @media (max-width: 1100px)
        display: grid
        grid-template-columns: 1fr
        grid-gap: 0.5em
        

.button_bottom
    display: flex
    color: #fff
    background-color: #4B7196
    margin-left: 5px
    align-items: center
    cursor: pointer
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-top-left-radius: 0.15rem
    border-bottom-left-radius: 0.15rem
    border: none
    transition: all .3s

    &:hover
        background-color: #2B3956

// Bottom description text styles
.bottom_text
    // margin: 0 2em
    width: 75%
    word-wrap: break-word
    text-align: justify
    @media (max-width: 1100px)
        margin: 1em 0 0 0
        width: 100%
        text-align: center
        
.wrap_button
    width: 206px
    display: flex
    @media (max-width: 1100px)
        width: 100%
        justify-content: center

.wrap_button-bot
    width: 206px
    display: flex
    @media (max-width: 1100px)
        width: 100%
        justify-content: center

.button_top
    max-width: 153px

.wrap_triangle
    display: flex
    color: #fff
    background-color: #4B7196
    align-items: center
    cursor: pointer
    padding: 0.375rem 0.35rem
    font-size: 1rem
    line-height: 1.5
    border: none
    border-top-right-radius: 0.15rem
    border-bottom-right-radius: 0.15rem
    transition: all .3s
    &:hover
        background-color: #1b70ca

.head_line
    font-size: 0.9em
    grid-template-columns: 11% 18% 28% 8% 10% 15% 10%

.line
    font-size: 0.9em
    word-wrap: normal
    display: grid
    grid-template-columns: 11% 18% 28% 8% 10% 15% 10%
    @media (max-width: 1100px)
        display: grid
        grid-template-columns: 1fr
        grid-gap: 0.5em

.line > a
    font-weight: 500
    color: #4B7196 !important

.line >  a:hover
    color: #2B3956 !important


.wrap__items
    font-size: 0.9rem
    color: black
    padding: 0.75rem 0
    vertical-align: top
    text-align: left
    padding-left: 1em

.wrap__items:not(:first-child)
    border-top: 1px solid #dee2e6

.wrap__items:nth-child(2n)
    border-radius: 0px
    background-color: #CFE2F3

.lei
    // margin-left: 10px

.wrap_input-bot
    width: 206px
    font-weight: 300
    @media (max-width: 1100px)
        width: 100%
        justify-content: center

.status
    color: #4B7196
    text-transform: uppercase
    font-weight: 500
.status-rejected
    color: #E8464E
.status-complete
    color: #12B100
    font-weight: 600
.status-manual
    color: #2B3956

.dropdown-item
    & > .loading-wrapper
        display: flex
        justify-content: center
        align-items: center
        width: 90px
        height: 30px
        margin-top: auto
        margin-bottom: auto
        margin-left: auto
        margin-right: 2rem
        @media (max-width: 1100px)
            margin-left: 0px
    & > button
        color: #fff
        background-color: #4B7196
        border-color: #4B7196
        border: 1px solid transparent
        font-size: 1rem
        line-height: 1.5
        border-radius: 0.25rem
        padding: 0px
        margin-top: auto
        margin-bottom: auto
        margin-left: auto
        margin-right: 2rem
        &:hover
            background-color: #395673
        @media (max-width: 1100px)
            margin-left: 0px
        & > span
            padding: 4px 8px
