.footerBottom
    margin-top: 10px

.footer-head-left
    font-weight: bold
    margin: 0
    line-height: 1.5
    color: #CFE2F3

.footer-head-center
    font-weight: bold
    color: #CFE2F3
    font-size: 1rem
    line-height: 1.5
    margin: 0
    @media (max-width: 800px) 
        text-align: center
        margin-top: 2em

.footer-head-center-second
    font-weight: bold
    color: #CFE2F3
    font-size: 1rem
    line-height: 1.5
    margin-top: 2em
    
    
.social-item-link-footer 
    margin-right: 10px
    vertical-align: text-bottom
    color: white
    text-decoration: none
    font-size: 0.9rem
    &:hover
        color: #CFE2F3

.footer-link 
    display: block
    font-size: 0.9rem
    margin-top: 1em
    max-width: 100%
    text-decoration: none
    color: white
    &:hover
        color: #CFE2F3
    @media (max-width: 800px) 
        text-align: center

.left__wrap 
    margin-top: 1em

.centr__wrap 
    margin-top: 1em

.right__wrap 
    margin-top: 1em
    display: flex
    align-items: center
    @media (max-width: 800px) 
        justify-content: center

.left__logo
    margin: 1em 0
    color: white
    display: flex
    &:hover
        color: #CFE2F3
    @media (max-width: 800px) 
        justify-content: center

.right__logo
    margin-top: 1em
    display: flex
    @media (max-width: 800px) 
        justify-content: center

.status__page
    color: #00b67a
    display: flex
    text-decoration: none
    &:hover
        color: #CFE2F3
    @media (max-width: 800px) 
        justify-content: center

.social__logo
    margin: 0 1em 0 0
    color: white
    display: flex
    &:hover
        color: #CFE2F3
    @media (max-width: 800px) 
        justify-content: center

.right__text
    margin-top: 1em
    line-height: 1.5
    display: flex
    font-size: 0.9rem
    @media (max-width: 800px) 
        justify-content: center

.img_position
    padding: 0
    vertical-align: middle