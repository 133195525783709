// Big Container
.container_big
//    @media(max-width: 768px)
//        display: flex
//        justify-content: space-between
//        flex-wrap: wrap
//        width: 100%

// START title_wrap

.title_wrap
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin-top: 2rem
    @media(max-width: 768px)
        width: 100%

.title_inner-button
    display: flex
    justify-content: flex-end
    flex-wrap: wrap
    margin-top: 2em

.button_cancel
    display: flex
    height: 40px
    align-items: center
    color: #fff
    background-color: #e8464e
    border-color: #e8464e
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all 0.3s
    justify-content: flex-end
    flex-wrap: wrap
    &:hover
        background-color: red
    @media(max-width: 768px)
        // flex-basis: 100%
        margin-bottom: 10px

.button_next
    display: flex
    height: 40px
    align-items: center
    margin-left: 0.5em
    color: #fff
    background-color: #4B7196
    border-color: #4B7196
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all .3s
    justify-content: flex-end
    &:hover
        background-color: #395673
    &:disabled
        background-color: #6c757d
        border-color: #6c757d

.button_search
    // display: flex
    height: 40px
    // width: 120px
    width: 100%
    // align-items: center
    // margin-left: 5px
    margin-top: 1.5em
    color: #fff
    background-color: #4B7196
    border-color: #4B7196
    border: 1px solid transparent
    // padding: auto
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all .3s
    text-align: center
    // vertical-align:middle
    // justify-content: center
    &:hover
        background-color: #395673
    .text
        margin-left: auto
        margin-right: auto

.button_select
    display: flex
    width: 80px
    align-items: center
    margin: 0 auto
    color: #fff
    background-color: #4B7196
    border-color: #4B7196
    border: 1px solid transparent
    padding: auto
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all .3s
    text-align: center
    vertical-align: middle
    justify-content: center
    &:hover
        background-color: #395673
    .text
        margin-left: auto
        margin-right: auto
    @media(max-width:768px)
        // width: auto
        font-size: 0.9rem
        padding: 0.1em 0.5em

.button_select_selected
    background-color: #2B3956
    border-color: #2B3956

.button_text
    margin: 0 0.5em

// END title_wrap

// START checkbox_wrap
.checkbox_wrap
    margin-top: 20px

// END checkbox_wrap

// START tab_name
.tab_name
    margin-top: 10px

// START card_body
.card_body
    margin-top: 10px
    padding-top: 10px
    margin-bottom: 30px
    border: 1px solid rgba(0,0,0,.125)
    @media(max-width:1100px)
        width: 100%

// END card_body


// START Form
.input_wrap
    padding: 10px 25px 30px 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr 0.8fr 0.6fr 0.5fr
    gap: 2em
    justify-items: stretch
    align-items: center
    @media (max-width: 1100px) 
        padding-bottom: 20px
        display: grid
        grid-template-columns: 1fr
        grid-gap: 0.5em

.input_wrap_states
    padding: 10px 25px 30px 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr 0.8fr 0.8fr 0.6fr 0.5fr
    gap: 2em
    justify-items: stretch
    align-items: center
    @media (max-width: 1100px) 
        padding-bottom: 20px
        display: grid
        grid-template-columns: 1fr
        grid-gap: 0.5em

// START Form
.input_wrap_years
    padding: 0 25px
    margin-top: 30px
    width: 35%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr
    align-items: center
    // grid-gap: 2em
    @media (max-width: 768px) 
       width: 100%
       // grid-template-columns: 1fr
       // grid-gap: 0.5em
    
.search_wrap
    width: 500px
    height: 42px
    margin-top: 10px

.search_frame
    height: 41px
    margin-top: 5px
    border-left: 5px solid #4B7196
    border-radius: 5px

// .table_wrap
//    margin-top: 1.5em
//    padding: 0 25px 0 25px
//    width: 100%
//    word-wrap: normal
//    @media (max-width: 768px) 
//        margin-top: 0
//        text-align: center
//        padding: 1.5em 0 0 0

.table_wrap
    margin-top: 1rem
    padding: 0 25px
    width: 100%
    word-wrap: normal
    thead
        background: #f8f9fa
    tr
        border: 0.5px solid #cfe2f3
        padding: 0.5em 0
    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)
        margin-top: 0
        text-align: center
        padding: 1em 0 0 0
        table, thead, tbody, th, td, tr 
            display: block
        thead tr
            position: absolute
            top: -9999px
            left: -9999px
        tr
            // border: 0.5px solid #cfe2f3
            border: none
            padding: 0.5em 0
        tr:nth-child(odd)
            background-color: #cfe2f3
        tr:nth-child(even)
            background-color: #fff
        td
            border: none
            // border-bottom: 1px solid #eee
            position: relative
            // padding-left: 50%
            padding: 0.4em 0.4em
        td:before
            // position: absolute
            // top: 6px
            // left: 6px
            // width: 45%
            padding-right: 5px
            white-space: nowrap
        td:nth-of-type(1):before
            content: "LEI:"
            font-weight: 500
        td:nth-of-type(2):before
            content: "Legal Name:"
            font-weight: 500
        td:nth-of-type(3):before
            content: "Country:"
            font-weight: 500
        td:nth-of-type(5):before
            content: "Next renewal date:"
            font-weight: 500
            align-content: center

        

.price_text
    font-size: 1.25rem
    font-weight: bold
    // right: 6px

.input_price_text
    margin-top: 15px
    margin-right: 20px
    
    float: right

.company_status
    color: red
    margin-left: 10px

.wrap_l2
    margin: 25px
    text-align: justify

.wrap_l2_right
    margin: 25px
    text-align: right
    line-height: 1.5

.Q 
    margin: 1em 0
    line-height: 1.5

.button_yes
    align-items: center
    color: #fff
    background-color: #4B7196
    border-color: #4B7196
    margin-top: 0.5rem
    border: 1px solid transparent
    padding: 0.1rem 1rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all 0.3s
    &:hover
        background-color: #395673

.button_yes_clicked
    align-items: center
    color: #fff
    background-color: #395673
    border-color: #395673
    margin-top: 0.5rem
    border: 4px solid transparent
    padding: 0.1rem 1rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all 0.3s
    &:hover
        background-color: #395673

.button_no
    align-items: center
    margin-left: 0.5rem
    margin-top: 0.5rem
    color: #fff    
    background-color: #4B7196
    border-color: #4B7196
    border: 1px solid transparent
    padding: 0.1rem 1rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: #395673

.button_no_clicked
    align-items: center
    margin-left: 0.5rem
    margin-top: 0.5rem
    color: #fff    
    background-color: #395673
    border-color: #395673
    border: 4px solid transparent
    padding: 0.1rem 1rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: #395673

.l2_message
    width: 100%
    background-color: #cfe2f3
    color: #2b3956
    text-align: justify
    padding: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    transition: opacity .6s
    font-size: 1em
    @media (max-width: 768px) 
        font-size: 0.75em

.warning_message
    width: 100%
    background-color: #ccc
    color: #2b3956
    text-align: center
    padding: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    transition: opacity .6s
    font-size: 1em
    @media (max-width: 768px) 
        font-size: 0.75em
        text-align: justify


// END Form

.tooltip
  position: relative
  font-size: 0.75rem
  // border-bottom: 1px dotted black

  &:before
    content: attr(data-tooltip)
    position: absolute
    width: 200px
    background-color: #2b3956
    color: #fff
    text-align: justify
    padding: 20px
    line-height: 1.5
    border-radius: 5px
    z-index: 100
    opacity: 0
    transition: opacity .6s
    font-size: 0.75rem
    visibility: hidden
    top: calc(100% + 5px)
    left: 50%
    transform: translateX(-50%)

  &:after
    content: ""
    position: fixed
    left: calc(50% - 100px)
    // border-width: 5px
    // border-style: solid
    opacity: 0
    transition: opacity .6s
    border-color: #2b3956 transparent transparent transparent
    visibility: hidden

  &:hover:before, &:hover:after
    opacity: 1
    visibility: visible

  @media (max-width: 767px)
    &:before
      width: 200px
      left: 50%
      transform: translateX(-60%)


label
    margin-bottom: 15px

.custom-select
  position: relative
  display: block
  margin: 0
  // z-index: 10
  border-radius: 5px
  border: 1px solid #e6e6e6
  border-left: 5px solid #4B7196
  width: 100%
  height: 39px
  outline: none 
  padding-left: 0.5rem
  margin-top: 5px
  line-height: 1rem
  &:invalid
    border-color: #e8464e

  select
    outline: none
    background: transparent
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    border-radius: 0
    margin: 0
    display: block
    width: 100%
    padding: 12px 55px 15px 15px
    font-size: 14px
    color: black
    border: 1px solid #e6e6e6
  
  &:after
    position: absolute
    right: 0
    top: 0
    width: 50px
    height: 100%
    line-height: 38px
    // content: '\2228'
    text-align: center
    color: black
    font-size: 24px
    // border-left: 1px solid #ccc
    z-index: -1

.input-comments-wrapper
    margin: 20px 25px 25px
    line-height: 1.5

    .textarea_custom
        margin-top: 10px
        width: 100%
        word-wrap: normal
        line-height: 1.5
        padding: 10px
        border-radius: 5px
        border: 1px solid rgba(0,0,0,.125)

.status
    color: #4B7196
    text-transform: uppercase
    font-weight: 500
.status-rejected
    color: #E8464E

.radio_wrapper
    margin: 0px 25px 10px 25px
    display: flex
    flex-direction: row
    justify-content: flex-start
    alignItems: center
    line-height: 1.5

    label
        margin-bottom: 0