.select
    position: relative
    width: 500px
    margin: 10px 0px

.select-title
    border-radius: 5px
    border-left: 5px solid #4B7196

.select-dropdown
    position: absolute
    top: 65px
    z-index: 100
    overflow: hidden
    background: #fff
    transition: all .6s
    left: 0
    width: 100%
    box-shadow: none
    height: 0px
    scrollbar-width: thin
    scrollbar-color: blue orange
    transition: all 0.3s
    & > ul
        list-style-type: none
        width: 100%
        display: block
        border-radius: 5px
        
        & > li
            padding: 10px
            width: 100%
            cursor: pointer
            &:hover
                background: #cfe2f3
    &::-webkit-scrollbar 
        width: 0px
.select-dropdown-active
    box-shadow: 0 1px 1px 1px #e6e6e6
    border-bottom-right-radius: 0.25em
    border-bottom-left-radius: 0.25em


.placeholder
    margin-bottom: 5px

.placeholder-required
    color: #4b7196



.input
    width: 100%
    outline: none
    border: 1px solid #e6e6e6
    padding: 10px
    padding-right: 20px

.show
    position: absolute
    top: 35px
    right: 10px
    cursor: pointer
    transition: all .6s
    &.active
        transform: rotate(-180deg)