// START title_wrap

.title_wrap
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin-top: 2rem
    @media(max-width: 768px)
        width: 100%

.title_inner-button
    display: flex
    justify-content: flex-end
    flex-wrap: wrap
    margin-top: 2em


.button_cancel
    display: flex
    height: 40px
    align-items: center
    color: #fff
    background-color: #e8464e
    border-color: #e8464e
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all 0.3s
    justify-content: flex-end
    flex-wrap: wrap
    &:hover
        background-color: red
    @media(max-width: 768px)
        // flex-basis: 100%
        margin-bottom: 10px

.button_next
    display: flex
    height: 40px
    align-items: center
    margin-left: 0.5em
    color: #fff
    background-color: #4B7196
    border-color: #4B7196
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all .3s
    justify-content: flex-end
    &:hover
        background-color: #395673
    &:disabled
        background-color: #6c757d
        border-color: #6c757d
        cursor: arrow

.button_back
    display: flex
    height: 40px
    align-items: center
    margin-right: 5px
    color: #fff
    background-color: #4B7196
    border-color: #4B7196
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.15rem
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: #395673

.button_next-rotate
     transform: rotate(180deg)

.button_text
    margin-left: 5px

// END title_wrap

// START checkbox_wrap
.checkbox_wrap
    margin-top: 20px

// END checkbox_wrap

// START tab_name
.tab_name
    // margin-top: 20px
    @media(max-width: 768px)
        width: 100%
        text-align: center

// START card_body
.card_body
    padding: 25px 25px
    margin-top: 10px
    margin-bottom: 30px
    width: 100%
    border: 1px solid rgba(0,0,0,.125)
    @media(max-width: 768px)
       // border: 0

.card_wrap
    margin: 0px
    display: flex
    align-items: center
    justify-content: center
    

// END card_body

.input_wrap
    // padding: 0 25px 0 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 2em
    justify-items: stretch
    align-items: center
    @media (max-width: 768px) 
        // padding-bottom: 20px
        grid-template-columns: 1fr
        grid-gap: 0.1em

.input_wreck
    // padding: 0 25px 0 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr 0.5fr 0.5fr
    gap: 2em
    justify-items: stretch
    align-items: center
    @media (max-width: 768px) 
        // padding-bottom: 20px
        grid-template-columns: 1fr
        grid-gap: 0.1em

.textarea_custom
    margin-top: 20px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr
    // gap: 2em
    justify-items: stretch
    align-items: center
    line-height: 1.5
    padding: 10px
    border-radius: 5px
    border: 1px solid rgba(0,0,0,.125)
    // box-shadow: 1px 1px 1px #999


.input_file_wrap
    display: left
    margin-left: 30px
    margin-top: 10px
    margin-bottom: 15px
    justify-content: space-around

// START parent_relationship

.submit_relationship-wrap
    border-radius: 0.25rem
    margin-top: 30px
    align-items: center
    font-weight: 300
    border: 1px solid rgba(0,0,0,.125)

.submit_title
    border-radius: calc(0.15rem - 1px) calc(0.15rem - 1px) 0 0
    padding: 1.25rem
    background-color: rgba(0,0,0,.03)
    border-bottom: 1px solid rgba(0,0,0,.125)
    font-weight: bold

.submit_description
    padding: 0.75rem 1.25rem

.submit_text
    margin-top: 10px
    margin-left: 20px
    margin-right: 20px
    text-align: justify

.ul
    margin-left: 20px

.submit_subtitle
    font-weight: bold
    margin-top: 30px

.payment_text
    font-weight: bold
    font-size: 18px
    margin-left: 15px

.popup
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  display: flex
  justify-content: center
  align-items: center
  z-index: 9999

.popup-content 
  background-color: white
  padding: 20px
  text-align: center
  border-radius: 5px

.card_errors
    margin: 2rem 0
    width: 100%
    text-align: center
    font-weight: 600
    line-height: 1.5
    

// END parent_relationship