
.input
    transition: all .3s
    width: 100%
    padding: 10px
    font-size: 14px
    color: #8b8b8b
    outline: none
    &:focus
        box-shadow: 0 0 5px .2rem rgba(0,123,255,.25)
        border: 1px solid rgba(0,123,255,.25)
        border-left: 5px solid #CFE2F3

.disabled
    opacity: 0.5
    & > div > input:focus
        box-shadow: none
        border: 1px solid #ced4da
        border-left: 5px solid #CFE2F3
        user-select: none

.input-error
    border-left: 5px solid red
    &:focus
        border: 1px solid white
        border-left: 5px solid red
        box-shadow: 0 0 10px .2rem #ff000040

.input-wrap
    input[type=file]::file-selector-button
        background-color: #4B7196
        padding: 4px 10px 4px 10px
        border-radius: 3px
        translateX: 0px
        margin-left: 0px
    input[type=file]::file-selector-button:hover
        background-color: #395673
    & > .placeholder
        margin-bottom: 2px

.input-wrapper
    display: flex
    align-items: center
    gap: 5px

.input-wrapper > div
    font-size: 1rem
