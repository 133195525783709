.wrapper{
    display: flex;
    align-items: center;
    padding: 40px;
    border: 1px solid rgba(0,0,0,.125);
    gap: 40px;
}

.card{
    border: 1px solid rgba(0,0,0,.125);
    width: 100%;
    border-radius: 5px;
}

.header{
    padding: 1em 1em;
    border-left: none;
    width: 100%;
    background-color:  #CFE2F3;
}

.header > h3 {
    font-size: 1em;
    font-weight: 600;
}

.card > ul {
    list-style: none;
    font-weight: 700;
    padding: 0 1em 1em 1em;
    
}

.card > ul > li {
    margin-top: 10px;
    font-size: .9em;
}

@media (max-width: 1100px) {
    .wrapper{
        border: none;
        display: flex;
        flex: 100%;
        flex-wrap: wrap;
        padding-bottom: 0
    }

}