//START settings__title-wrap
.settings__title-wrap
    display: flex
    align-items: center
    margin-top: 2rem

.settings__email-wrap
    margin: 0
//END settings__title-wrap


// START Form
.input_wrap
    display: flex
    justify-content: space-between
    margin-top: 100px

.input_wrap-job
    display: flex
    justify-content: flex-start
    margin-top: 10px
    margin-left: 25px
// END Form

.container
    // margin-top: 20px
    // margin-bottom: 3em
    width: 50%
    @media (max-width: 767px)
        width: 100%

.wrap
    display: flex
    align-items: center
    justify-content: space-between
    gap: 30px
    padding: 20px
    font-size: 1rem

.buttons
    display: flex
    align-items: left
    @media (max-width: 767px)
        align-items: center
        justify-content: center
