.advertise {
    margin-top: 0.5rem;
    float: right;
    font-size: small;
}
.banner-wrap-1 {
    word-wrap: normal;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 0;
    }
}
.banner-wrap-2 {
    word-wrap: normal;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 0;
    }
}
.banner {
    border-radius: 10px;
    width: 100%;
    margin: 2.5rem 0 0rem 0;
}