
  
  .button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    float: right;    
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #4b7196;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
  }
   
  .button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #5d8dbd;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  .payment-status {
    margin: 10px 10px;
  }

  .input,
  .select,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    height: 40px;
    padding: 10px 14px;
    font-size: 34px;
    box-shadow: rgba(50, 50, 93, 0.14902) 1px 2px 3px,
      rgba(0, 0, 0, 0.0196078) 1px 1px 1px;
    border: 1px;
    outline: 0;
    border-radius: 4px;
    background: white;
  }
  
  .input::placeholder,
  .select::placeholder {
    color: #aab7c4;
  }
  
  .input:focus,
  .select:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  
  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
  
  .StripeElement.PaymentRequestButton {
    height: 40px;
  }
  



.stripe-form {
    margin: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border: 2px solid #e7e7e7;
    border-radius: 5px;
    text-align: left;
}

.submit-btn{
    margin: 10px 0;
    width: 50%;
    align-self: center;
    background: #32325d;
    padding: 10px 14px;
    color: white;
    border-radius: 5px;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 15px;
}

.form-row{
    display: flex;
    flex-direction: column;
}

.form-input{
    margin: 10px 0 20px;
    height: 40px;
    padding: 0 15px;
    display: block;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    border: 1px;
    outline: 0;
    border-radius: 4px;
    background: white;  
    box-shadow: rgba(50, 50, 93, 0.14902) 1px 2px 3px,
    rgba(0, 0, 0, 0.0196078) 1px 1px 1px;
}

.form-select{
  margin: 10px 0 20px;
  height: 40px;
  padding: 0 15px;
  display: block;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  border: 1px;
  outline: 0;
  border-radius: 4px;
  background: white;
  box-shadow: rgba(50, 50, 93, 0.14902) 1px 2px 3px,
  rgba(0, 0, 0, 0.0196078) 1px 1px 1px;
}

.card-errors{
    color: red;
}