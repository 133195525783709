//No data found in table
.not-found {
    margin-top: 1.5rem; 
    padding-left: 0.9rem;
    @media (max-width: 1100px) {
        text-align: center;
    }
}

.subtitle__text-bottom {
    margin-top: 1em;
}

.search__input {
    display: flex;
    margin-top: 1.5rem;
    @media (max-width: 900px) {
        margin-top: 2rem;
        flex: 100%;
        flex-wrap: wrap;
    }
}


.grid {
    margin-top: 1.5em;
    @media (max-width: 1100px) {
        display: grid;
        grid-template-columns: 1fr;
    }
}


.line {
    word-wrap: normal;
    display: grid;
    grid-template-columns: 20% 30% 15% 17.5% 17.5%;
    padding-left: 1em;
    @media (max-width: 1100px) {
        display: none;
    }
  }

.wrap__items {
    font-size: 1rem;
     color: #212529;
     padding: 0.75rem 0;
     vertical-align: top;
     text-align: left;
     
}

.wrap__items:not(:first-child) {
    border-top: 1px solid #dee2e6;
}

.item {
    font-weight: 700;
    @media (max-width: 1100px) {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.lei {
    margin-left: 0;
}

.inner__items {
    margin-top: 1.5em;
    border-top: 1px solid #dee2e6;
}

.wrap__items:nth-child(2n) {
    background-color: rgba(0,0,0,.05);
}

// PAGINATE START

.container__paginate {
    display: flex;
    list-style-type: none;
    margin-top: 2rem;
    justify-content: center;
    cursor: pointer;
    color: #4B7196;
    background-color: #fff;
}

.page__class-name {
    padding: 4px;
    margin-right: 4px;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    &:hover {
        background-color: #CFE2F3;
        color: #2B3956;
    }
}

.page__class-name > a {
    text-decoration: none;
}

.previous__class-name {
    padding: 4px;
    margin-right: 4px;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    &:hover {
        background-color: #CFE2F3;
        color: #2B3956;
    }
}

.previous__class-name > a {
    text-decoration: none;
}

.break__class-name > a {
    text-decoration: none;
}

.next__class-name {
    padding: 4px;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    &:hover {
        background-color: #CFE2F3;
        color: #2B3956;
    }
}

.next__class-name > a {
    text-decoration: none;
}

.active__class-name {
    background-color: #2B3956;
    border-color: #CFE2F3;
    color: #fff;
    &:hover {
        background-color: #2B3956;
        color: #CE0000;
    }
}



// PAGINATE STOP
