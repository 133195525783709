.text
    margin-top: 20px

.img
    text-align: center
    padding-top: 2em

.img_position
    padding: 1em 1.5em 0 0
    vertical-align: middle

a
  text-decoration: underline

.text__large 
    color: black
    line-height: 1.5

.description_centered 
    margin-top: 1rem
    color: black
    line-height: 1.5
    text-align: center

.text_tab
    font-size: 1.2rem
    font-weight: 600
    text-align: left
    padding-right: 1em
    
@media (max-width: 1100px)
  .text_tab
    padding-bottom: 1em

@media (max-width: 600px)
  .img
    display: flex
    flex-wrap: wrap
    padding-top: 1em

  .img_position
    flex: 100%