.company-row {
    margin: 2rem;
    display: grid;
    line-height: 1.5;
    grid-template-columns: 120px auto;
    gap: 2rem;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 2rem;
    box-shadow: 5px 5px 5px rgb(237, 237, 237);
    align-items: top;
    position: relative;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 1.2rem;
    }
}

.truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
}

.company-row2 {
    margin: 2rem;
    display: grid;
    line-height: 1.5;
    grid-template-columns: 120px 1.2fr 1fr 1fr;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 2rem;
    box-shadow: 5px 5px 5px rgb(237, 237, 237);
    align-items: top;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 1.2rem;
    }
}
.company-row2 .cell {
    margin-left: 2rem;
    @media (max-width: 768px) {
        margin-left: 0;
    }
}
.company-row2 .first-row {
    grid-column: 2 / -1;
    margin-left: 2rem;
    margin-bottom: 1rem;
    @media (max-width: 768px) {
        margin-left: 0;
        margin-bottom: 0;
    }
}
.mr-8 {
    margin-right: 2rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.mt-4 {
    margin-top: 1rem;
}
.mt-8 {
    margin-top: 2rem;;
}

.qr-button {
    margin-top: 5px;
    padding: 5px;
    text-align: center;
    font-size: 0.9rem;
}

.qr-row {
    display: grid !important;
    line-height: 1.5;
    grid-template-columns: 180px;
    text-align: center;
    margin: 0 2rem;
    @media (max-width: 768px) {
        word-wrap: break-word;
        justify-content: center;
        align-items: center;
    }
}

.callout {
    padding: 20px;
    margin: 20px 2rem;
    border: 1px solid #ffaeae;
    border-left: 5px solid #ff6464;
    background-color: #ffd3d3;
    border-radius: 5px;
    text-align: justify;
  }

.more-info {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    padding: 5px;
}

.tooltip-content {
    display: none;
    position: absolute;
    right: 0;
    bottom: 100%; /* Position above the label */
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 0px; /* Give some space between the label and tooltip */
    white-space: break-spaces;
    z-index: 1000; /* Ensures it floats above other content */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional: adds a slight shadow for better visibility */
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    min-width: 200px;
}

.more-info:hover .tooltip-content {
    display: block; /* Show the tooltip on hover */
    opacity: 1;
    visibility: visible;
}
