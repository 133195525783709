/*@import "./styles/style.css"; */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.error {
  width: 500px;
  padding: 20px;
  border-radius: 10px;
  color: white;
  position: fixed;
  top: 30px;
  right: 30px;
  background-color: red;
}

h1 {  
font-size: 2rem;
font-weight: bold;
line-height: 1.5;
}

h2 {    
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
}

h3 {    
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}