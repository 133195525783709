
.input
    transition: all .3s
    width: 100%
    padding: 10px
    font-size: 14px
    color: black
    border-radius: 5px
    border: 1px solid #ced4da
    border-left: 5px solid #4B7196
    outline: none
    line-height: 1rem
    &:focus
        box-shadow: 0 0 5px .2rem #CFE2F3
        border: 1px solid rgba(0,123,255,.25)
        border-left: 5px solid #2B3956

.disabled
    //opacity: 0.5
    & > div > input:focus
        box-shadow: none
        border: 1px solid #ced4da
        border-left: 5px solid #CFE2F3
        user-select: none

.input-error
    border-left: 5px solid #CE0000
    &:focus
        border: 1px solid white
        border-left: 5px solid red
        box-shadow: 0 0 10px .2rem #ff000040
.input-wrap
    & > .placeholder
        margin-bottom: 5px

.input-wrapper
    display: flex
    align-items: center
    gap: 5px

.input-wrapper > div
    font-size: 1rem


.input-checkbox
    margin-right: 7px
