.container
    display: flex
    flex-direction: row
    // flex-wrap: wrap
    justify-content: center
    gap: 1em
    margin: 5em 5em 0 5em
    @media (max-width: 768px)
        margin: 5em 1em

.box-left
    flex: 50%
    max-width: 450px
    background-color: #fff
    border: 1px solid lightgrey
    border-radius: 5px 
    justify-content: center
    padding: 0 40px
    @media (max-width: 768px)
        flex: 100%
        margin: 0

.title 
    color: #2B3956
    font-size: 2.5rem
    margin-left: 0
    margin-top: 20px
    margin-bottom: 20px
    text-align: left
    font-weight: 300
    font-family: Roboto, sans-serif

.box-right
    flex: 50%
    max-width: 450px
    background-color: #fff
    // background-image: url("../../../assets/icons/login/login.png")
    background-repeat: no-repeat
    background-size: cover
    background-position: bottom
    border: 1px solid lightgrey
    border-radius: 5px
    @media (max-width: 768px)
        flex: 0%
        display: none

.box-right-title
    color: #2B3956
    font-size: 1rem
    padding: 40px 40px 20px 40px
    text-align: justify
    font-weight: 400
    font-family: Roboto, sans-serif
    line-height: 1.5
    word-wrap: break-word

.box-right-title > b
    font-weight: 500

.box-right-text
    font-size: 0.9rem
    text-align: justify
    padding: 0 40px 40px 40px
    line-height: 1.5

.box-right-text > a
    color: #2B3956

.footer
    justify-content: center
    margin-top: 2rem

.wrap
    display: flex
    align-items: center
    justify-content: space-between
    gap: 30px
    padding: 20px
    font-size: 1em