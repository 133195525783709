// START title_wrap

.title_wrap
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin-top: 2rem
    @media(max-width: 768px)
        width: 100%

.title_inner-button
    display: flex
    justify-content: flex-end
    flex-wrap: wrap
    margin-top: 2em

.button_back
    display: flex
    height: 40px
    align-items: center
    margin-right: 0.5em
    color: #2B3956
    background-color: #fff
    border: 1px solid #2B3956
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 5px
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: #4B7196
        color: white
        border: 1px solid #4B7196

.button_cancel
    display: flex
    height: 40px
    align-items: center
    color: #fff
    background-color: #e8464e
    border-color: #e8464e
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 5px
    cursor: pointer
    transition: all 0.3s
    justify-content: flex-end
    flex-wrap: wrap
    &:hover
        background-color: red
    @media(max-width: 768px)
        // flex-basis: 100%
        margin-bottom: 10px

.button_next
    display: flex
    height: 40px
    align-items: center
    margin-left: 0.5em
    color: #fff
    background-color: #4B7196
    border-color: #4B7196
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 5px
    cursor: pointer
    transition: all .3s
    justify-content: flex-end
    &:hover
        background-color: #395673
    &:disabled
        background-color: #6c757d
        border-color: #6c757d

.button_next-rotate
     transform: rotate(180deg)

.button_text
    margin-left: 5px

// END title_wrap

// START card_body
.card_body
    margin-top: 10px
    padding-top: 10px
    padding-bottom: 20px
    margin-bottom: 30px
    border: 1px solid rgba(0,0,0,.125)
    @media(max-width:1100px)
        width: 100%

// END card_body

.section_title
    padding: 20px 25px 10px 25px
    width: 100%
    word-wrap: normal
    line-height: 1.5
    font-weight: bold

.details_wrap
    padding: 0px 25px 10px 25px
    width: 100%
    line-height: 1.5

.input_wrap
    padding: 0 25px 10px 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 1em 2em
    justify-items: stretch
    @media (max-width: 768px) 
        grid-template-columns: 1fr
        grid-gap: 0.5em

// END parent_relationship

.checkboxes
    padding-top: 1.5rem

.checkbox_wrapper
    padding: 5px 25px
    display: flex
    cursor: pointer
    flex-wrap: nowrap
    align-items: center
    
    Input, input
        display: flex
        align-items: center

    a
        display: inline
        white-space: nowrap

    Input[type="checkbox"]
        margin-right: 1em

.label
    margin-bottom: 0px
    text-align: justify
