.title 
    color: #2B3956
    font-size: 2rem
    margin-left: 0
    margin-top: 20px
    margin-bottom: 10px
    text-align: left
    font-weight: bold

.container
    display: flex
    flex-direction: row
    // flex-wrap: wrap
    justify-content: center
    gap: 1em
    margin: 2rem 5em 0 5em
    @media (max-width: 768px)
        margin: 2rem 1em

.box-left
    flex: 50%
    max-width: 415px
    background-color: #fff
    border: 1px solid lightgrey
    border-radius: 5px 
    justify-content: center
    padding: 0 40px
    @media (max-width: 768px)
        flex: 100%
        margin: 0

.box-right
    flex: 50%
    max-width: 450px
    background-color: #fff
    background-image: url("../../../assets/icons/login/login.png")
    background-repeat: no-repeat
    background-size: cover
    background-position: bottom
    border: 1px solid lightgrey
    border-radius: 5px
    @media (max-width: 768px)
        flex: 0%
        display: none

.box-right-title
    color: #2B3956
    font-size: 1rem
    padding: 40px 40px 20px 40px
    text-align: justify
    line-height: 1.5
    word-wrap: break-word

.box-right-title > b
    font-weight: 700

.box-right-text
    font-size: 0.9rem
    text-align: left
    padding-left: 40px

.box-right-text > a
    font-size: 1em
    color: #2B3956

.form-text
    font-size: 0.9rem

.footer
    justify-content: center
    margin: 40px 0 0 0

.footer-text
    font-size: 0.8em
    text-align: center
    margin: 20px 0 0 0 

.footer-text > a
    font-size: 1em
    color: #4B7196

.footer-text-forgot
    font-size: 0.8em
    text-align: center

.footer-text-forgot > a
    font-size: 1em
    color: grey

.pm-awesome-divider
    height: 1px
    width: 100%
    border-top: solid thin #f0f0f0
    margin: 24px 0
    text-align: center


.pm-awesome-divider:after 
    content: attr(data-label)
    display: inline-block
    background: #fff
    padding: 0 32px
    position: relative
    top: -15px
    font-size: 12px
    color: #9b9b9b

.google-button
    background-color: white
    color: black
    padding: 2px
    border: solid #4b7196 1px
    border-radius: 5px
    cursor: pointer
    line-height: 1.4
    display: flex
    float: none
    width: 100%
    margin-top: 20px
    -webkit-transition: all 0.3s
    transition: all 0.3s
    text-decoration: none
    align-items: center

.google-button:hover
    background-color: #4b719630

.google-logo
    display: inline-block
    padding: 2px
    width: 30px
    margin-left: 8px

.google-text
    line-height: 2.65
    display: inline-block
    width: 100%
    height: 40px
    text-align: center
