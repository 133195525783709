.container
    // display: -webkit-box
    // display: -ms-flexbox
    display: flex
    // -webkit-box-orient: horizontal
    // -webkit-box-direction: normal
    // -ms-flex-direction: row
    flex-direction: row
    // -webkit-box-pack: justify
    // -ms-flex-pack: justify
    justify-content: space-between
    // position: relative
    max-width: 1100px
    margin: 0 auto
    flex: 1 1 auto
    // box-sizing: content-box
    

.inner__navigate 
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    position: relative
    margin-left: 0

.homebutton
    text-align: left
    font-size: 1rem !important
    font-weight: 400 !important

.navbar__top-wrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-end
    position: relative
    margin-left: 0

.button
    // background: #fff
    border: none
    color: #2B3956
    cursor: pointer
    display: flex
    align-items: center

.button__text 
    padding: 0px 4px 0px 0px
    font-size: 1rem !important
    font-weight: 400 !important

.modal
    z-index: 100
    position: absolute
    top: 60px
    right: 0px
    width: 160px
    padding: 5px 0px
    background-color: #ffffff
    overflow: hidden
    border: 1px solid rgba(0,0,0,.15)
    font-size: 0.8rem
    border-radius: 0.5em

.records
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 5px
    height: 30px
    padding: 3px 33px
    font-weight: 400
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: #CFE2F3
    &:first-child
        margin-left: -6px

.text
    color: #000000
    font-size: 1rem
    font-weight: 400

.orders
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 5px
    height: 30px
    padding: 3px 26px
    font-weight: 400
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: #CFE2F3
        text-decoration: none
    &:first-child
        margin-left: -6px

.settings
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 5px
    height: 30px
    padding: 3px 33px
    font-weight: 400
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: #CFE2F3
    &:first-child
        margin-left: -6px

.children
    display: flex
    justify-content: space-around
    align-items: center
    height: 30px
    padding: 3px 18px
    font-weight: 400
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: #cfe2f3
    &:first-child
        margin-left: -6px

@media (max-width: 768px)
    .container
        width: 100%

    .homebutton
        text-align: left
        font-size: 1rem
        display: none

    .button__text 
        font-size: 1rem
