

.container {
    max-width: 1100px;
    width: 100%;
    margin: 2rem auto;
}

.lei-title {
    display: flex;
    margin: 0 2rem;
    align-items: center;
    @media screen and (max-width: 768px) {
        justify-content: center;
        text-align: center;
        margin: 0 auto;
    }
    @media (min-width: 769px) and (max-width: 1100px) {
        margin: 0 -10px;
    }
}

.lei-hero {
    display: flex;
    margin: 0 2rem;
    align-items: center;
    font-weight: 300;
    color: 2b3956;
    @media screen and (max-width: 768px) {
        justify-content: center;
        text-align: center;
        margin: 0 auto;
    }
}

.mt-4 {
    margin-top: 1rem;
}

.mt-8 {
    margin-top: 2rem;
}

/* h1 {
    color: #2B3956;
    font-size: 2rem;
    font-weight: bold;
    @media screen and (max-width: 1100px) {
        font-size: 2rem;
        margin: 0; 
    }
} */

h1 {
    line-height: 1.25;
}

.table-info {
    margin: 20px 2rem 0 2rem;
    /* @media screen and (max-width: 1100px) {
        padding: 0 2rem;
    } */
}

.row {
    display: flex;
    line-height: 1.5;
    width: 100%;
    @media (max-width: 768px) {
        /* display: grid; 
        gap: 1rem;
        grid-template-columns: 0.3fr 1fr;
        word-wrap: break-word; */
        justify-content: center;
        align-items: center;
    }
}

.subtitle {
    margin: 30px 2rem 10px 2rem;
    font-size: 1.25rem;
    font-weight: bold;
    /* @media screen and (max-width: 1100px) {
        padding: 0 2rem;
    } */

    /* @media (max-width: 768px) {
        text-align: center;
    } */
}

.status-ISSUED {
    background-color: green;
    padding: 0 0.5em;
    color: white;
}

.status-LAPSED {
    background-color: red;
    padding: 0 0.5em;
    color: white;
}

.status-SUBMITTED {
    background-color: #2B3956;
    padding: 0 0.5em;
    color: white;
}

.item-head {
    font-weight: bold;
}

.left {
    text-align: left;
}

.col-left {
    width: 30%;
}

.col-right {
    width: 70%;
}

.col-50 {
    width: 50%;
}

.striped-table {
    width: 100%;
    border-collapse: separate;
    padding: 0 2rem;
    /* @media (max-width: 1100px) {
        padding: 0 2rem;
    } */
  }
  
  .striped-table th,
  .striped-table td {
    padding: 8px;
    border: 0px solid #ddd;
    line-height: 1.5;
  }
  
  /* Striped row styles */
  .striped-table tr:nth-child(1n) {
    background-color: #f9f9f9;
  }
  .striped-table tr:nth-child(2n) {
    background-color: #cfe2f3;
  }


.title_inner-button {
    width: 100%;
    /* border-top: 1px solid lightgray; */
    display: flex;
    justify-content: end; 
    flex-wrap: wrap;
    margin-top: 2rem;
    padding: 1rem 2rem 0 2rem;
    @media screen and (max-width: 1100px) {
        justify-content: center;
    }
}

.button_back {
    display: inline-flex;
    align-items: center;
    height: 40px;
    align-items: center;
    margin-right: 0.5em;
    color: #2B3956;
    background-color: #fff;
    border: 1px solid #2B3956;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s;
    text-decoration: none
}

.button_back:hover {
    background-color: #4B7196;
    color: white;
    border: 1px solid #4B7196;
}

details {
    /* background-color: #f9f9f9; */
    border: 1px solid #ddd;
    /* border-radius: 4px; */
    padding: 10px;
    margin: 10px 0;
}

summary {
    display: flex; 
    align-items: center; 
    cursor: pointer;
    margin: -10px -10px -10px -10px;
    padding: 10px;
    cursor: pointer;
    background-color: #f9f9f9;
    list-style: none; /* Removes the default arrow icon in Chrome */
}

summary h3 {
    margin: 0; 
    font-size: 1rem;
    font-weight: bold;
}

summary:hover {
    background-color: #cfe2f3;
}

summary::-webkit-details-marker { /* Hide default arrow in Chrome */
    display: none;
}

details[open] summary {
    border-bottom: none;
    background-color: #cfe2f3;
    border-bottom: 1px solid #ddd;
}

details p {
    margin: 30px 10px 10px;
    line-height: 1.5;
}

details[open] {
    background-color: #ffffff;
}

/* Optional: Add an icon for the summary element */
summary:before {
    content: '▶';
    font-size: 12px;
    margin-right: 5px;
    vertical-align: middle;
    display: inline-block;
    transition: transform 0.3s ease;
}

details[open] summary:before {
    transform: rotate(90deg); /* Rotates the icon when details are open */
}

.contact {
    margin: 2rem 2.5rem 0 2rem;
    font-size: 1rem;
    text-align: center;
    /* font-weight: bold; */
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.w-full {
    width: 100%;
}

/* .qr-button {
    padding: 0 10px;
    text-align: center;
    font-size: 0.9rem;
}

.qr-row {
    display: grid;
    line-height: 1.5;
    grid-template-columns: 180px;
    text-align: center;
    @media (max-width: 768px) {
        word-wrap: break-word;
        justify-content: center;
        align-items: center;
    }
} */

.callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #cecece;
    border-left: 5px solid #4B7196;
    background-color: #cfe2f3;
    border-radius: 5px;
}

a {
    color: #4B7196;
    text-decoration: none;
}

  