table {
    border-collapse: collapse;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table-bordered {
    border: 1px solid #dee2e6
}

.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6
}

.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 3px;
    font-weight: bold;
}

