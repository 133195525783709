// START title_wrap

.title_wrap
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin-top: 20px
    @media(max-width: 768px)
        width: 100%

.title_inner-button
    display: flex
    justify-content: flex-end
    flex-wrap: wrap
    margin-top: 2em

.button_back
    display: flex
    height: 40px
    align-items: center
    margin-right: 0.5em
    color: #2B3956
    background-color: #fff
    border: 1px solid #2B3956
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 5px
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: #4B7196
        color: white
        border: 1px solid #4B7196

.button_cancel
    display: flex
    height: 40px
    align-items: center
    color: #fff
    background-color: #e8464e
    border-color: #e8464e
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 5px
    cursor: pointer
    transition: all 0.3s
    justify-content: flex-end
    flex-wrap: wrap
    &:hover
        background-color: red
    @media(max-width: 768px)
        // flex-basis: 100%
        margin-bottom: 10px

.button_next
    display: flex
    height: 40px
    align-items: center
    margin-left: 5px
    color: #fff
    background-color: #4B7196
    border-color: #4B7196
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 5px
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: #395673
    &:disabled
        background-color: #59a9ff
        border-color: #59a9ff

.button_next-rotate
     transform: rotate(180deg)

.button_text
    margin-left: 5px

// END title_wrap

// START card_body
.card_body
    margin-top: 10px
    // margin-bottom: 30px
    padding: 20px
    border: 0px
    width: 100%
    text-align: center

.card_wrap
    margin: 0px
    display: flex
    align-items: center
    justify-content: center
    

// END card_body

.input_wrap
    display: flex
    justify-content: space-around

.input_file_wrap
    display: left
    margin-left: 30px
    margin-top: 10px
    margin-bottom: 15px
    justify-content: space-around

// START parent_relationship

.submit_relationship-wrap
    border-radius: 0.25rem
    margin-top: 30px
    align-items: center
    font-weight: 300
    border: 1px solid rgba(0,0,0,.125)

.submit_title
    border-radius: calc(0.15rem - 1px) calc(0.15rem - 1px) 0 0
    padding: 1.25rem
    background-color: rgba(0,0,0,.03)
    border-bottom: 1px solid rgba(0,0,0,.125)
    font-weight: bold

.submit_description
    padding: 0.75rem 1.25rem

.submit_text
    margin-top: 10px
    margin-left: 20px
    margin-right: 20px
    text-align: justify

.ul
    margin-left: 20px

.submit_subtitle
    font-weight: bold
    margin-top: 30px

.payment_text
    font-weight: bold
    font-size: 18px
    margin-left: 15px

.iframe
    width: 100%
    height: 600px
    

// END parent_relationship