// START title_wrap

.title_wrap
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin-top: 2rem
    @media(max-width: 768px)
        width: 100%

.title_inner-button
    display: flex
    justify-content: flex-end
    flex-wrap: wrap
    margin-top: 2em

.button_back
    display: flex
    height: 40px
    align-items: center
    margin-right: 0.5em
    color: #2B3956
    background-color: #fff
    border: 1px solid #2B3956
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 5px
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: #4B7196
        color: white
        border: 1px solid #4B7196

.button_cancel
    display: flex
    height: 40px
    align-items: center
    color: #fff
    background-color: #e8464e
    border-color: #e8464e
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 5px
    cursor: pointer
    transition: all 0.3s
    justify-content: flex-end
    flex-wrap: wrap
    &:hover
        background-color: red
    @media(max-width: 768px)
        // flex-basis: 100%
        margin-bottom: 10px

.button_next
    display: flex
    height: 40px
    align-items: center
    margin-left: 0.5em
    color: #fff
    background-color: #4B7196
    border-color: #4B7196
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 5px
    cursor: pointer
    transition: all .3s
    justify-content: flex-end
    &:hover
        background-color: #395673
    &:disabled
        background-color: #6c757d
        border-color: #6c757d

.button_next-rotate
     transform: rotate(180deg)

.button_text
    margin-left: 5px

// END title_wrap

// START card_body
.card_body
    margin-top: 10px
    padding-top: 10px
    padding-bottom: 20px
    margin-bottom: 30px
    border: 1px solid rgba(0,0,0,.125)
    @media(max-width:1100px)
        width: 100%

// END card_body

.sa_wrap
    padding: 20px 25px 20px 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr
    gap: 2em
    justify-items: stretch
    align-items: center
    text-align: justify
    line-height: 1.5
    @media (max-width: 768px) 
        padding-bottom: 20px

.input_wrap
    padding: 0 25px 20px 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 1em 2em
    justify-items: stretch
    @media (max-width: 768px) 
        grid-template-columns: 1fr
        grid-gap: 0.5em

.input_file_wrap
    padding: 20px 25px 10px 25px
    // margin-bottom: 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 0.4fr 1fr
    grid-gap: 1em
    @media (max-width: 768px) 
       width: 100%
       grid-template-columns: 1fr
       grid-gap: 0.5em
       // justify-items: center

.input_file_container_invisible
    display: none

// START parent_relationship

//.submit_relationship-wrap
//    border-radius: 0.25rem
//    margin-top: 30px
//    align-items: center
//    font-weight: 300
//    border: 1px solid rgba(0,0,0,.125)

.submit_title
    border-radius: calc(0.15rem - 1px) calc(0.15rem - 1px) 0 0
    padding: 25px
    background-color: #CFE2F3 //rgba(0,0,0,.03)
    border-top: 1px solid rgba(0,0,0,.125)
    border-right: 1px solid rgba(0,0,0,.125)
    border-left: 1px solid rgba(0,0,0,.125)
    font-weight: bold
    @media (max-width: 768px) 
       text-align: center

.submit_description
    padding: 0.75rem 25px 25px 25px
    border: 1px solid rgba(0,0,0,.125)
    text-align: justify
    line-height: 1.5

.submit_text
    margin-top: 1em
    // margin-left: 25px
    // margin-right: 25px
    text-align: justify
    line-height: 1.5

.ul
    margin-left: 20px

.submit_subtitle
    font-size: 1.25rem
    font-weight: bold
    margin-top: 30px
    @media (max-width: 768px) 
       text-align: center

// END parent_relationship

.wrap_l2_right
    margin: 25px 0 25px 0px
    // text-align: right
    line-height: 1.5

.price_text
    font-size: 1.25rem
    font-weight: bold
    @media (max-width: 768px) 
       text-align: center

.price_text_total
    font-size: 1rem
    font-weight: bold
    // right: 6px

.tooltip
  position: relative
  border-bottom: 1px dotted black

  &:before
    content: attr(data-tooltip)
    position: fixed
    width: 200px
    background-color: #2b3956
    color: #fff
    text-align: justify
    padding: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    z-index: 1
    opacity: 0
    transition: opacity .6s
    left: calc(50% - 100px)
    font-size: 0.75em
    visibility: hidden

  &:after
    content: ""
    position: fixed
    left: calc(50% - 100px)
    border-width: 5px
    border-style: solid
    opacity: 0
    transition: opacity .6s
    border-color: #2b3956 transparent transparent transparent
    visibility: hidden

  &:hover:before, &:hover:after
    opacity: 1
    visibility: visible

.checkboxes
    padding-top: 1em



.label
    display: flex
    cursor: pointer
    flex-wrap: nowrap
    align-items: center
    
    Input, input
        display: flex
        align-items: center

    a
        display: inline
        white-space: nowrap

    Input[type="checkbox"]
        margin-right: 1em
