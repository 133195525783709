// START title_wrap

.title_wrap
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin-top: 20px
    @media(max-width: 768px)
        width: 100%

.title_inner-button
    display: flex
    justify-content: flex-end
    flex-wrap: wrap
    margin-top: 2em


.button_cancel
    display: flex
    height: 40px
    align-items: center
    color: #fff
    background-color: #e8464e
    border-color: #e8464e
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all 0.3s
    justify-content: flex-end
    flex-wrap: wrap
    &:hover
        background-color: red
    @media(max-width: 768px)
        // flex-basis: 100%
        margin-bottom: 10px

.button_next
    display: flex
    height: 40px
    align-items: center
    margin-left: 0.5em
    color: #fff
    background-color: #4B7196
    border-color: #4B7196
    border: 1px solid transparent
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    border-radius: 0.25rem
    cursor: pointer
    transition: all .3s
    justify-content: flex-end
    &:hover
        background-color: #395673
    &:disabled
        background-color: #6c757d
        border-color: #6c757d

.button_text
    margin: 0 0.5em

// END title_wrap

// START checkbox_wrap
.checkbox_wrap
    margin-top: 20px

// END checkbox_wrap

// START tab_name
.tab_name
    margin-top: 20px

// START card_body
.card_body
    margin-top: 10px
    padding: 20px 0
    margin-bottom: 30px
    border: 1px solid rgba(0,0,0,.125)
    @media(max-width:1100px)
        width: 100%

// END card_body


// START Form

.input_wrap
    padding: 0 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr 1fr 0.7fr 0.7fr
    gap: 2em
    justify-items: stretch
    align-items: center
    @media (max-width: 1100px) 
        // padding-bottom: 20px
        display: grid
        grid-template-columns: 1fr
        grid-gap: 0.5em

.input_wrap_3
    padding: 0 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 0.5fr 1fr 1fr
    gap: 2em
    justify-items: stretch
    align-items: center
    @media (max-width: 1100px) 
        // padding-bottom: 20px
        display: grid
        grid-template-columns: 1fr
        grid-gap: 0.5em

.input_wrap_4
    padding: 0 25px 10px 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr 0.4fr 0.4fr 0.4fr
    gap: 2em
    justify-items: stretch
    align-items: center
    @media (max-width: 1100px) 
        // padding-bottom: 20px
        display: grid
        grid-template-columns: 1fr
        grid-gap: 0.5em
        margin-bottom: 0

.input_wrap_5
    padding: 0 25px 10px 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr 0.4fr 1fr 0.4fr 0.4fr
    gap: 2em
    justify-items: stretch
    align-items: center
    @media (max-width: 1100px) 
        // padding-bottom: 20px
        display: grid
        grid-template-columns: 1fr
        grid-gap: 0.5em
        margin-bottom: 0

.input_wrap_address_4
    padding: 0 25px 10px 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr
    gap: 2em
    justify-items: stretch
    align-items: center
    @media (max-width: 1100px) 
        // padding-bottom: 20px
        display: grid
        grid-template-columns: 1fr
        grid-gap: 0.5em

.input_wrap_address_3
    padding: 0 25px 10px 25px
    width: 100%
    word-wrap: normal
    display: grid
    grid-template-columns: 1fr 0.5fr 0.5fr
    gap: 2em
    justify-items: stretch
    align-items: center
    @media (max-width: 1100px) 
        // padding-bottom: 20px
        display: grid
        grid-template-columns: 1fr
        grid-gap: 0.5em
        margin-bottom: 0

.summary_box
    margin: 10px 0 20px 0 
    line-height: 1.5
    font-size: 0.9rem
    text-align: justify
    border: 1px solid rgba(0,0,0,.125)
    border-radius: 5px
    background-color: #CFE2F3
    padding: 20px
    width: 100%

.input_file_wrap
    display: left
    margin: 10px 30px 0 30px
    justify-content: space-around

.search_wrap
    width: 100%
    height: 42px
    margin-top: 10px

.search_frame
    height: 41px
    margin-top: 5px
    border-left: 5px solid #4B7196
    border-radius: 5px

.card_errors
    margin-top: 20px

// END Form

.tooltip
  position: relative
  font-size: 0.75rem
  // border-bottom: 1px dotted black

  &:before
    content: attr(data-tooltip)
    position: absolute
    width: 200px
    background-color: #2b3956
    color: #fff
    text-align: justify
    padding: 20px
    line-height: 1.5
    border-radius: 5px
    z-index: 100
    opacity: 0
    transition: opacity .6s
    font-size: 0.75rem
    visibility: hidden
    top: calc(100% + 5px)
    left: 50%
    transform: translateX(-50%)

  &:after
    content: ""
    position: fixed
    left: calc(50% - 100px)
    // border-width: 5px
    // border-style: solid
    opacity: 0
    transition: opacity .6s
    border-color: #2b3956 transparent transparent transparent
    visibility: hidden

  &:hover:before, &:hover:after
    opacity: 1
    visibility: visible

  @media (max-width: 767px)
    &:before
      width: 100px
      left: 50%
      transform: translateX(-50%)

.custom-select
  position: relative
  display: block
  margin: 0
  // z-index: 10
  border-radius: 5px
  border: 1px solid #e6e6e6
  border-left: 5px solid #4B7196
  width: 100%
  height: 40px
  outline: none 
  padding-left: 0.5rem
  margin-top: 5px
  &:invalid
    border-color: #e8464e

  select
    outline: none
    background: transparent
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    border-radius: 0
    margin: 0
    display: block
    width: 100%
    padding: 12px 55px 15px 15px
    font-size: 14px
    color: black
    border: 1px solid #e6e6e6
  
  &:after
    position: absolute
    right: 0
    top: 0
    width: 50px
    height: 100%
    line-height: 38px
    // content: '\2228'
    text-align: center
    color: black
    font-size: 24px
    // border-left: 1px solid #ccc
    z-index: -1